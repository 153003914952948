.headerImage{
  background-image: url(./images/background.jpg);
  background-size: auto;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  height: 50vh;
}

.logo{
  height: 20vh;
  align-self: center;
}

.container{
  font-family:  Calibri, 'Trebuchet MS', sans-serif;
  font-size: larger;
  font-weight: 300;
}

.awards{
  text-align: center;
}

.fonts{
  vertical-align: middle;
}

.headers{
  font-family: "Averia Sans Libre" !important;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.textGrid{
  flex-direction: column !important;
  display: flex;
  justify-content: center;
  text-align: center;

}

.pricingText{
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 8px;
}

.awardText{
  margin: 30px 0px 30px 0px;
}

.icons{
  align-self: center;
  height: 70px;
  width: 70px;
  margin-top: 25px;
  margin-bottom: 5px;
}

.img{
  width: 100%;
}